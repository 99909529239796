
import { defineComponent, onBeforeMount, ref, reactive, toRefs, nextTick, provide } from 'vue'
import { useRouter } from 'vue-router'

import AddOrEditDialog from './addOrEditDialog.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { projectApi } from '@/api/modules/project'
import Crypoto from '@/utils/CryptoJS'

export default defineComponent({
  name: 'projectIndex',
  components: {
    AddOrEditDialog
  },
  setup (props, ctx) {
    const router = useRouter()

    const cry: any = new Crypoto()

    // 获取refs
    const tableListRef = ref()
    const addOrEditDialogRef = ref()
    const queryFormRef = ref()

    onBeforeMount(() => {
      data.init()
    })

    const data: any = reactive({
      info: JSON.parse(localStorage.info),
      list: [],
      loading: true,
      query: {
        page: 1,
        paginate: 20,
        type_id: '',
        name: '',
        user_type1_name: '',
        date_interval: [],
        status: 0,
        code: ''
      },
      total: 0, // 总条数
      statusColor: ['', '#000', '#409eff', '#E6A23C', '#A6A9AD', '#F56C6C'],
      // 是否显示排序
      ifSorting: false,

      // 初始化
      init () {
        queryFormRef.value && queryFormRef.value.resetFields()
        data.getList('place')
      },

      // 获取列表
      async getList (place: string) {
        // 重置页数
        if (place) {
          data.query.page = 1
          // 滚动条回到顶部
          nextTick(() => {
            tableListRef.value && (tableListRef.value.$el.children[2].scrollTop = 0)
          })
        }

        const { res } = await projectApi.getList(data.query)
        console.log('获取列表', res.data)
        data.loading = false
        data.list = res.data
        data.total = res.total

        // 是否排序状态
        data.ifSorting = false
      },
      // 每页显示条数和当前页码
      handleSizeChange (newSize: number) {
        data.query.paginate = newSize
        data.getList()
        // 滚动条回到顶部
        nextTick(() => {
          tableListRef.value.$el.children[2].scrollTop = 0
        })
      },
      handleCurrentChange (newPage: number) {
        data.query.page = newPage
        data.getList()
        // 滚动条回到顶部
        nextTick(() => {
          tableListRef.value.$el.children[2].scrollTop = 0
        })
      },

      // 新增、编辑
      onAddOrEdit (id: number | string) {
        addOrEditDialogRef.value.showAddOrEdit(id)
      },

      // 删除
      async onDel (id: number) {
        const confirmResult = await ElMessageBox.confirm('是否删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err: any) => err)
        if (confirmResult !== 'confirm') return

        await projectApi.del({ id })
        ElMessage.success('删除成功')
        data.getList()
      },

      // 查看详情
      async onShowDetails (id: number) {
        router.push('/project/list/details?id=' + id)
      },

      // 导出
      async exportFun () {
        const confirmResult = await ElMessageBox.confirm('是否导出到表格?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err: any) => err)
        if (confirmResult !== 'confirm') return

        // const res = await data.$api.index.export()
        // await exportExcel(res, '用户列表')
        ElMessage.success('导出成功')
      },

      // 状态改变
      async editStatus (item: any) {
        // console.log('状态改变', item)
        try {
          const confirmResult = await ElMessageBox.confirm('是否更变状态？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).catch((err) => err)
          if (confirmResult !== 'confirm') throw new Error('取消操作')

          const form: any = {
            id: item.id,
            is_show: item.is_show
          }
          await projectApi.changeShow(form)
          ElMessage.success('状态改变成功')
          data.getList()
        } catch (error) {
          // 恢复原来的样子
          item.is_show = item.is_show === 1 ? 2 : 1
        }
      },

      // 排序
      onSortingShow () {
        for (const i of data.list) {
          i.newSorting = i.sort
        }
        data.ifSorting = true
      },
      async onSortingSave () {
        const confirmResult = await ElMessageBox.confirm('是否重新排序？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err: any) => err)
        if (confirmResult !== 'confirm') return

        // console.log('排序确认', data.list)

        const form: any = {
          ids: [],
          sorts: []
        }
        for (const i of data.list) {
          form.ids.push(i.id)
          form.sorts.push(i.newSorting)
        }
        // console.log('form', form)
        await projectApi.changeSort(form)
        ElMessage.success('排序成功')
        data.ifSorting = false

        data.getList()
      },

      // 跳转帮助中心
      onHelp (item: any) {
        // console.log('item', item)
        // console.log('info', data.info)
        const dataItem: any = {
          name: item.name,
          code: item.code,
          userName: data.info.username,
          userMobile: data.info.mobile
        }
        // console.log('dataItem', dataItem)
        const encryptData = cry.encrypt(JSON.stringify(dataItem))
        // console.log('encryptData', encryptData)

        // window.open('http://localhost:8080?dataItem=' + encryptData)
        window.open('https://help.ozkoalas.cn?dataItem=' + encryptData)
      }
    })

    // 注入
    provide('updateList', data.getList)

    return {
      ...toRefs(data),
      tableListRef,
      addOrEditDialogRef,
      queryFormRef
    }
  }
})
