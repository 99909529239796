
import { defineComponent, ref, reactive, toRefs, inject, nextTick, getCurrentInstance } from 'vue'

import { ElMessage } from 'element-plus'
import { projectApi } from '@/api/modules/project'

export default defineComponent({
  name: 'projectAddOrEditDialog',
  setup (props, ctx) {
    // 获取全局变量
    const { proxy } = getCurrentInstance() as any

    // 获取refs
    const addOrEditFormRef = ref()

    // 接收父级的方法
    const updateList: any = inject('updateList')

    // 新增、编辑表单数据
    const addOrEditFormInit: any = () => {
      return {
        name: '',
        type_id: '',
        code: '',
        user_id_type1: '',
        user_ids_type2: [],
        date: [],
        describe: ''
      }
    }

    const data = reactive({
      addOrEditDialog: false,
      addOrEditType: 0, // 0新增,1编辑
      addOrEditTypeTitle: ['新增', '编辑'],
      addOrEditForm: addOrEditFormInit(),
      addOrEditRules: {
        name: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
        type_id: [{ required: true, message: '请选择项目类型', trigger: 'change' }],
        code: [{ required: true, message: '请输入项目代号', trigger: 'blur' }],
        user_id_type1: [{ required: true, message: '请选择项目负责人', trigger: 'change' }],
        user_ids_type2: [{ required: true, message: '请选择项目成员', trigger: 'change' }],
        date: [{ required: true, message: '请选择项目时间段', trigger: 'change' }],
        describe: [{ required: true, message: '请输入项目描述', trigger: 'blur' }]
      },
      determineBtnLoading: false,

      // 对话框显示
      async showAddOrEdit (id: number) {
        data.addOrEditType = id ? 1 : 0

        // 有id时赋值
        if (id) {
          const { res } = await projectApi.getInfo({ id })
          console.log('根据id获取详情', res)
          data.addOrEditForm = res

          // 数据处理
          data.addOrEditForm.date = [res.start_date, res.end_date]

          data.addOrEditForm.typeSelectedObj = {
            id: res.type_id,
            name: res.type_name
          }

          data.addOrEditForm.userIdType1SelectedObj = {
            user_id: res.user_type1.user_id,
            username: res.user_type1.username
          }
          data.addOrEditForm.user_id_type1 = res.user_type1.user_id

          const user_ids_type2: number[] = []
          const userIdType2SelectedObj: any = []
          for (const i of res.user_type2) {
            user_ids_type2.push(i.user_id)
            userIdType2SelectedObj.push({
              user_id: i.user_id,
              username: i.username
            })
          }
          data.addOrEditForm.userIdType2SelectedObj = userIdType2SelectedObj
          data.addOrEditForm.user_ids_type2 = user_ids_type2
        }
        data.addOrEditDialog = true
      },

      // 对话框关闭
      addOrEditCancel () {
        // console.log('对话框关闭')
        addOrEditFormRef.value.resetFields()
        data.addOrEditForm = Object.assign({}, addOrEditFormInit())
      },

      // 确认
      addOrEditDetermine () {
        console.log('data.addOrEditForm', data.addOrEditForm)
        addOrEditFormRef.value.validate(async (valid: any) => {
          if (!valid) return

          // 处理提交数据
          if (data.addOrEditForm.date.length) {
            data.addOrEditForm.start_date = data.addOrEditForm.date[0]
            data.addOrEditForm.end_date = data.addOrEditForm.date[1]
          }
          const form = proxy.$globalFun.deepClone(data.addOrEditForm)
          form.user_ids_type2 = JSON.stringify(form.user_ids_type2)

          try {
            data.determineBtnLoading = true
            await projectApi.save(form)
            ElMessage.success(`${data.addOrEditTypeTitle[data.addOrEditType]}成功`)
            data.addOrEditDialog = false
            data.determineBtnLoading = false

            // 父级更新列表
            updateList('place')
          } catch (error) {
            data.determineBtnLoading = false
          }
        })
      }
    })

    return {
      ...toRefs(data),
      addOrEditFormRef
    }
  }
})
